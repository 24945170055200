import React, { useRef, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Table,
  InputGroup,
} from "react-bootstrap";
import api from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import Swal from "sweetalert2";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import PartyMasterDD from "../PartyMaster/PartyMasterDD";
import ReactDOMServer from "react-dom/server";
import PrintJangad from "./PrintJangad";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import ReactDOM from "react-dom";
import { ISOToDateTime, filterParams } from "../../Components/Common/Common";

export default function Jangad() {
  const [partyMasterName, setPartyMasterName] = useState([]);
  const [formData, setFormData] = useState({
    start_date: new Date().toISOString().substr(0, 10),
    end_date: new Date().toISOString().substr(0, 10),
    jangadNo: "",
    partyMasterID: 0,
  });
  const [bagNo, setbagNo] = useState("");
  const [bagData, setBagData] = useState([]);
  const [showBagTable, setShowBagTable] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedParty, setSelectedParty] = useState("");
  const [jangadAggridData, setJangadAggridData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [JangadID, setJangadID] = useState(0);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    getAllPartyMsater();
    getJangadDataAggrid();
  }, []);

  useEffect(() => {
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month starts from 0
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    // Set selectedDate to today's date when component mounts
    setSelectedDate(getTodayDate());
  }, []); // Empty dependency array ensures this effect runs only once

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedValue = name === "jangadNo" ? value.toUpperCase() : value;
    setFormData({ ...formData, [name]: updatedValue });
  };

  const handlepartymasterChange2 = (option) => {
    setSelectedParty(option);
    setFormData({
      ...formData,
      partyMasterID: option ? option.value : null,
    });
  };
  const FindASItxt = useRef(null);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      api
        .get(`Jangad/FindBag?BagNo=${bagNo}`)
        .then((result) => {
          setbagNo("");
          FindASItxt.current.focus();
          let data = result.data;
          if (data.flag === false) {
            toast.error(data.message);
            return;
          } else {
            data.data.forEach((element) => {
              setBagData((oldArray) => [...oldArray, element]);
            });
            console.log(bagData);
            setShowBagTable(true);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred while fetching bag details.");
        });
    }
  };

  const getAllPartyMsater = () => {
    return api
      .get("PartyMaster/All")
      .then((result) => {
        setPartyMasterName(result.data.data);
        return result.data.data;
      })
      .catch((error) => {
        console.log(error);
        throw error; // Rethrow the error to propagate it to the caller
      });
  };

  // ag-grid
  const getJangadDataAggrid = () => {
    api
      .get("Jangad/AllJangad")
      .then((result) => {
        setJangadAggridData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const InsertJangad = () => {
    if (!selectedDate) {
      toast.error("Please Select Date.");
      return;
    }
    if (!selectedParty) {
      toast.error("Please Select Party.");
      return;
    }
    if (bagData.length === 0) {
      toast.error("Please add bag details.");
      return;
    }

    setShowLoader(true);
    const data = {
      JangadID: JangadID,
      Date: selectedDate,
      PartyMasterID: selectedParty.value,
      BagData: bagData,
    };

    const endpoint =
      JangadID === 0 ? "Jangad/InsertJangadData" : "Jangad/EditJangad";

    api[JangadID === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        toast.success(result.data.message);
        setBagData([]);
        setShowBagTable(false);
        setSelectedDate("");
        setSelectedParty(null);
        setShowLoader(false);
        setJangadID(0);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
        setShowLoader(false);
      })
      .finally((result) => {
        getJangadDataAggrid();
      });
  };

  const DeletePartyMaster = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Jangad/DeleteJangad?JangadID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getJangadDataAggrid();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const handlepartymasterChange = (option) => {
    setSelectedParty(option);
  };

  const selectDDJangadID = (partyMasterID) => {
    getAllPartyMsater()
      .then((data) => {
        const selectedParty = data.find(
          (f) => f.partyMasterID === partyMasterID
        );
        if (selectedParty) {
          var option = {
            value: partyMasterID,
            label: selectedParty.partyName,
          };
          handlepartymasterChange(option);
          setShowPopup(false);
        } else {
          console.error("Party master not found for ID:", partyMasterID);
          // Optionally, handle the case where party master is not found
        }
      })
      .catch((error) => {
        console.error("Error fetching party master data:", error);
        // Optionally, handle the error
      });
  };

  const UpdateActiveAndDeActive = (jangadID, markAsPAid) => {
    const Active = !Boolean(markAsPAid);
    api
      .patch(
        `Jangad/UpdateMarkAsPAid?JangadID=${jangadID}&MarkAsPAid=${Active}`
      )
      .then((result) => {
        getJangadDataAggrid();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllCompany = () => {
    debugger;
    api
      .get("CompanyMaster/CompanyMasterAll")
      .then((result) => {
        setCompanyData(result.data.data);
      })
      .catch((error) => {
        toast.error("An error occurred");
      });
  };

  const openPrintTab = (jangadID) => {
    debugger;
    // Create an iframe element
    const printWindow = document.createElement("iframe");
    printWindow.style.display = "none";

    api
      .get(`Jangad/AllStoneDataPrint?JangadID=${jangadID}`)
      .then(({ data }) => {
        getAllCompany();
        // Render component with fetched data
        const Single =
          data.data.listJangadDetails.remarks == "ASI TO SINGLE" ? true : false;
        const printComponent = (
          <PrintJangad
            data={data.data}
            SingleType={Single}
            companyData={companyData}
          />
        );
        const printContent =
          ReactDOMServer.renderToStaticMarkup(printComponent);

        // Append the iframe to the document body
        document.body.appendChild(printWindow);

        // Access iframe document
        const iframeDocument =
          printWindow.contentDocument || printWindow.contentWindow.document;

        // Write the content to the iframe document
        iframeDocument.write(`
                <html>
                    <head>
                        <title>Print</title>
                        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
                    </head>
                    <body>
                        ${printContent}
                        <hr>
                        ${printContent} <!-- Duplicate the content here -->
                    </body>
                </html>
            `);

        // Trigger printing once
        setTimeout(() => {
          printWindow.contentWindow.print();
        }, 400);
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  // date filter data
  const getJangad = () => {
    api
      .get(
        `Jangad/AllJangadDetailDateWise?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&JangadNo=${formData.jangadNo}&PartyMasterID=${formData.partyMasterID}`
      )
      .then(({ data }) => setJangadAggridData(data.data))
      .catch((error) => {
        console.error("Error fetching Jangad data:", error);
        // Handle error appropriately
      });
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    const fileName = "JangadData.xlsx";

    const limitedData = jangadAggridData.map(({ jangadID, ...rest }) => rest);

    const headers = Object.keys(limitedData[0]);

    const wsData = [
      headers,
      ...limitedData.map((obj) => headers.map((key) => obj[key])),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  };

  const getSingleJangad = (value) => {
    api
      .get(`Jangad/AllStoneDataPrint?JangadID=${value}`)
      .then((result) => {
        setSelectedDate(format(result.data.data.list.date, "yyyy-MM-dd"));
        setSelectedParty({
          value: result.data.data.list.partyMasterID,
          label: result.data.data.list.partyName,
        });
        setBagData(result.data.data.listJangadDetails);
        setShowBagTable(true);
        setJangadID(result.data.data.list.jangadID);
        console.log(result.data.data.list.jangadID);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Define the handleDelete function
  const handleDelete = (index) => {
    const updatedBagData = [...bagData];
    updatedBagData.splice(index, 1);
    setBagData(updatedBagData);
  };

  //Print button
  const PrintPreview = ({ stoneDetails }) => {
    return (
      <>
        <div style={{}}>
          <table
            className="table table-bordered"
            style={{
              fontSize: "12px",
              lineHeight: "8px",
              marginTop: "-1px",
              borderColor: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={16}
                  style={{ textAlign: "center", fontSize: "14px" }}
                >
                  Jangad List
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>No</th>
                <th style={{ textAlign: "center" }}>JangadNo</th>
                <th style={{ textAlign: "center", minWidth: "140px" }}>Date</th>
                <th style={{ textAlign: "center", minWidth: "10px" }}>
                  PartyName
                </th>
                <th style={{ textAlign: "center" }}>Pcs</th>
                <th style={{ textAlign: "center" }}>Weight</th>
                <th style={{ textAlign: "center" }}>MarkAsPAid</th>
              </tr>
            </thead>
            <tbody>
              {jangadAggridData.map((jangad, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td style={{ textAlign: "center" }}>{jangad.jangadNo}</td>
                  <td style={{ textAlign: "center", minWidth: "140px" }}>
                    {ISOToDateTime(jangad.date)}
                  </td>
                  <td style={{ textAlign: "center" }}>{jangad.partyName}</td>
                  <td style={{ textAlign: "center" }}>{jangad.totalpcs}</td>
                  <td style={{ textAlign: "center" }}>
                    {jangad.totalweight.toFixed(2)}
                  </td>

                  <td style={{ textAlign: "center" }}>
                    {/* Display 'Paid' or 'UnPaid' based on markAsPAid */}
                    {jangad.markAsPAid ? "Paid" : "UnPaid"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const printJangadReport = () => {
    const StoneDetailsForPrint = jangadAggridData;

    const printWindow = window.open("", "_blank");

    printWindow.document.write(
      '<html><head><title>Print Barcode</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"><style>{{width:"760px;"}}</style></head><body>'
    );

    ReactDOM.render(
      <PrintPreview stoneDetails={StoneDetailsForPrint} />,
      printWindow.document.body
    );

    printWindow.document.write("</body></html>");

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);
  };

  const columnDefs = [
    {
      headerName: "JangadNo",
      field: "jangadNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      field: "date",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          if (isNaN(date.getTime())) {
            return params.value; // Return the original value if it's not a valid date
          } else {
            const formattedDate = date.toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            return formattedDate;
          }
        }
        return params.value; // Return the original value if it's empty or undefined
      },
    },
    {
      headerName: "PartyName",
      field: "partyName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pcs",
      field: "totalpcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Weight",
      field: "totalweight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        // Check if the value is a number and format it to 2 decimal places
        return params.value !== null && params.value !== undefined
          ? params.value.toFixed(2)
          : "";
      },
    },
    {
      headerName: "MarkAsPaid",
      field: "markAsPAid",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        if (data.markAsPAid) {
          return (
            <button
              className="btn btn-outline-success mb-2"
              onClick={() =>
                UpdateActiveAndDeActive(data.jangadID, data.markAsPAid)
              }
            >
              <strong>Paid</strong>
            </button>
          );
        } else {
          return (
            <button
              className="btn btn-danger mb-2"
              onClick={() =>
                UpdateActiveAndDeActive(data.jangadID, data.markAsPAid)
              }
            >
              <strong>UnPaid</strong>
            </button>
          );
        }
      },
    },
    {
      headerName: "Print List",
      width: 100,
      field: "jangadID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) => (
        <div onClick={() => openPrintTab(value)}>
          <ion-icon name="print-outline" class="iconsFonts"></ion-icon>
        </div>
      ),
    },
    {
      headerName: "Edit",
      width: 100,
      field: "jangadID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleJangad(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 80,
      field: "jangadID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => DeletePartyMaster(value)}
        ></ion-icon>
      ),
      pinned: "right",
    },
  ];

  return (
    <>
      {/* {showLoader && <Loader />} */}
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>Jangad</span>
        </Card.Header>
        <Card.Body>
          <Form
            noValidate
            // validated={validated}
            // onSubmit={InsertPartyMaster}
            // ref={CreateParty}
          >
            <Row>
              <Col md={6}>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="PartyName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Date :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="date"
                        name="selectedDate"
                        placeholder="Select Date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value PartyName
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="adderss">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Party :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8 ">
                    <div className="mb-3 d-flex justify-content-between">
                      <Select
                        value={selectedParty}
                        onChange={handlepartymasterChange}
                        options={partyMasterName.map((option) => ({
                          value: option.partyMasterID,
                          label: option.partyName,
                        }))}
                        placeholder="--Select Party--"
                      />
                      <div className="selectEndIcon input-group-text">
                        <i
                          className="fa fa-plus plusIcon"
                          onClick={() => setShowPopup(true)}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="PartyName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Find ASI :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="text"
                        name="bagNo"
                        ref={FindASItxt}
                        placeholder="Find ASI"
                        value={bagNo}
                        onChange={(e) => setbagNo(e.target.value)}
                        onKeyDown={handleKeyPress}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value PartyName
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        {showBagTable && bagData.length > 0 && (
          <Card>
            <Card.Header>
              <span>Table Data</span>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    {/* <th>ASI No</th> */}
                    <th>Stone No</th>
                    <th>Length</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th>Weight</th>
                    <th>Delete Stone</th>
                  </tr>
                </thead>
                <tbody>
                  {bagData.map((bagItem, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {/* <td>{bagItem.bagNo}</td> */}
                      <td>{bagItem.stoneNo}</td>
                      <td>{bagItem.length}</td>
                      <td>{bagItem.width}</td>
                      <td>{bagItem.height}</td>
                      <td>{bagItem.weight}</td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDelete(index)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        )}

        <div className="card-footer margin_top10">
          <Button
            type="button"
            variant="primary"
            className="mb-265px"
            onClick={InsertJangad}
          >
            {JangadID === 0 ? "Save" : "Update"}
          </Button>
        </div>
      </Card>

      <Card>
        <Card.Header>
          <span>Jangad Data</span>
        </Card.Header>
        <Card.Header>
          <Row className="justify-content-end">
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <b className="">Start</b>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Expected start_date
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <b className="">End</b>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Expected end_date
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="JangadNo" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <b>JangadNo</b>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="jangadNo"
                    value={formData.jangadNo}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a Jangad number.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="PartyName" className="d-flex">
                <Form.Label style={{ marginRight: "20px" }}>
                  PartyName:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Select
                    value={selectedParty}
                    onChange={handlepartymasterChange2}
                    options={partyMasterName.map((option) => ({
                      value: option.partyMasterID,
                      label: option.partyName,
                    }))}
                    placeholder="--Select Party--"
                  />

                  <Form.Control.Feedback type="invalid">
                    Please enter a Party name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} className="text-end">
              <Button variant="primary" onClick={getJangad}>
                Search
              </Button>
              <Button
                variant="primary"
                style={{ marginLeft: "10px" }}
                onClick={() => printJangadReport()}
              >
                <i class="fa fa-print"></i> Print
              </Button>
              <Button
                type="button"
                variant="success"
                className="btn btn-primary"
                onClick={exportToExcel}
                style={{ marginLeft: "10px" }}
              >
                <i class="fa fa-file-excel"></i> Export to Excel
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={jangadAggridData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          />
        </Card.Body>
      </Card>

      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        size="xl"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Party</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <Form.Group>
              <PartyMasterDD partyMasterID={selectDDJangadID}></PartyMasterDD>
            </Form.Group>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
